//--------------------------------------------------------------
// Imports Section
//--------------------------------------------------------------
import * as React       from "react"
import { useEffect }    from 'react'
import { useRef }       from 'react'
import { gsap }         from 'gsap'
import Title            from "../../components/Resume/components/Title"
import Ripple           from "../../components/Backgrounds/Ripple"

import "./PresentationSection.scss"


//--------------------------------------------------------------
// Component Section
//--------------------------------------------------------------
const PresentationSection = () => {

    //----------------------------------------------------------
    // Initialization Section
    //----------------------------------------------------------

    //----------------------------------------------------------
    // Lifecycle Eventhandler Section
    //----------------------------------------------------------
    useEffect(() => {
    }, []);


    //----------------------------------------------------------
    // Render Section
    //----------------------------------------------------------
    return (
        <section
            id="presentation"
            className="presentation"
        >
            <Ripple
                color={0x2a2a2a}
                speed={15}
                amount={100}
                separation={3}
                orbit={false}
            />
            <div className="presentation-container scroll">
                <Title
                    title={'Hi there...'}
                    span={''}
                />
                <div style={{paddingTop: '15px'}}>
                    <h2>Video Presentation Coming Soon...</h2>
                </div>
            </div>
        </section>
    )

}

//--------------------------------------------------------------
// Exports Section
//--------------------------------------------------------------
export default PresentationSection
